import { Github } from '@styled-icons/boxicons-logos/Github';
import { Twitter } from '@styled-icons/boxicons-logos/Twitter';
import { Instagram } from '@styled-icons/boxicons-logos/Instagram';
import { Linkedin } from '@styled-icons/boxicons-logos/Linkedin';

const Icons = {
    Github,
    Twitter, 
    Instagram,
    Linkedin
}

export default Icons;
