// const handle = "pompeopaula";

const links = [
    {
        label: "Github",
        url: 'https://github.com/paulapompeo'
    },
    {
        label: "Instagram",
        url: 'https://www.instagram.com/paulapompeo_'
    },
    {
        label: "Twitter",
        url: 'https://twitter.com/pompeopaula'
    },
    {
        label: "Linkedin",
        url: 'https://www.linkedin.com/in/paulapompeo'
    },
]

export default links;
